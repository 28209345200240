{
  "auth": {
    "user_pool_id": "eu-north-1_zlLtygz6T",
    "aws_region": "eu-north-1",
    "user_pool_client_id": "4rev4u79lsc0or3vr2ki2798q1",
    "identity_pool_id": "eu-north-1:c40181b5-a15d-445f-aff3-2d4996cb7196",
    "mfa_methods": [],
    "standard_required_attributes": [
      "email"
    ],
    "username_attributes": [
      "email"
    ],
    "user_verification_types": [
      "email"
    ],
    "groups": [
      {
        "Everyone": {
          "precedence": 0
        }
      },
      {
        "Admin": {
          "precedence": 1
        }
      }
    ],
    "mfa_configuration": "NONE",
    "password_policy": {
      "min_length": 8,
      "require_lowercase": true,
      "require_numbers": true,
      "require_symbols": true,
      "require_uppercase": true
    },
    "unauthenticated_identities_enabled": true
  },
  "data": {
    "url": "https://4ucj4oiofbcbhhmacxd5vrfu3m.appsync-api.eu-north-1.amazonaws.com/graphql",
    "aws_region": "eu-north-1",
    "default_authorization_type": "AMAZON_COGNITO_USER_POOLS",
    "authorization_types": [
      "AWS_IAM"
    ],
    "model_introspection": {
      "version": 1,
      "models": {
        "User": {
          "name": "User",
          "fields": {
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "email": {
              "name": "email",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "givenName": {
              "name": "givenName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "familyName": {
              "name": "familyName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "jobTitle": {
              "name": "jobTitle",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "mobilePhoneCountryCode": {
              "name": "mobilePhoneCountryCode",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "mobilePhone": {
              "name": "mobilePhone",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "owner": {
              "name": "owner",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "clientAccountId": {
              "name": "clientAccountId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "createdBy": {
              "name": "createdBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "updatedBy": {
              "name": "updatedBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "disabled": {
              "name": "disabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "Users",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "userId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "provider": "userPools",
                    "ownerField": "owner",
                    "allow": "owner",
                    "identityClaim": "cognito:username",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "userId",
            "sortKeyFieldNames": []
          }
        },
        "ClientAccount": {
          "name": "ClientAccount",
          "fields": {
            "clientAccountId": {
              "name": "clientAccountId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "companyName": {
              "name": "companyName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "industry": {
              "name": "industry",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "address1": {
              "name": "address1",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "address2": {
              "name": "address2",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "city": {
              "name": "city",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "state": {
              "name": "state",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "zip": {
              "name": "zip",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "currencyCode": {
              "name": "currencyCode",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "currencySymbol": {
              "name": "currencySymbol",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "annualEventCount": {
              "name": "annualEventCount",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "averageEventAttendance": {
              "name": "averageEventAttendance",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "licenseKey": {
              "name": "licenseKey",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "licenseKeyValidUntil": {
              "name": "licenseKeyValidUntil",
              "isArray": false,
              "type": "AWSDate",
              "isRequired": false,
              "attributes": []
            },
            "readGroups": {
              "name": "readGroups",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "writeGroups": {
              "name": "writeGroups",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "createdBy": {
              "name": "createdBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "updatedBy": {
              "name": "updatedBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "disabled": {
              "name": "disabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "ClientAccounts",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "clientAccountId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "groupClaim": "cognito:groups",
                    "provider": "userPools",
                    "allow": "groups",
                    "operations": [
                      "read"
                    ],
                    "groupsField": "readGroups",
                    "groupField": "groups"
                  },
                  {
                    "groupClaim": "cognito:groups",
                    "provider": "userPools",
                    "allow": "groups",
                    "groupsField": "writeGroups",
                    "groupField": "groups",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  },
                  {
                    "allow": "private",
                    "operations": [
                      "create"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "clientAccountId",
            "sortKeyFieldNames": []
          }
        },
        "PixelInstallationTracking": {
          "name": "PixelInstallationTracking",
          "fields": {
            "pixelInstallationTrackingId": {
              "name": "pixelInstallationTrackingId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "clientAccountId": {
              "name": "clientAccountId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "domain": {
              "name": "domain",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "readGroups": {
              "name": "readGroups",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "writeGroups": {
              "name": "writeGroups",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "createdBy": {
              "name": "createdBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "updatedBy": {
              "name": "updatedBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "disabled": {
              "name": "disabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "PixelInstallationTrackings",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "pixelInstallationTrackingId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "pixelInstallationTrackingId",
            "sortKeyFieldNames": []
          }
        },
        "Event": {
          "name": "Event",
          "fields": {
            "eventId": {
              "name": "eventId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "logoImageName": {
              "name": "logoImageName",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "logoImageUrl": {
              "name": "logoImageUrl",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "websiteUrl": {
              "name": "websiteUrl",
              "isArray": false,
              "type": "AWSURL",
              "isRequired": false,
              "attributes": []
            },
            "openingDate": {
              "name": "openingDate",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            },
            "closingDate": {
              "name": "closingDate",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            },
            "location": {
              "name": "location",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "attendeeCount": {
              "name": "attendeeCount",
              "isArray": false,
              "type": "Float",
              "isRequired": false,
              "attributes": []
            },
            "valuePerShare": {
              "name": "valuePerShare",
              "isArray": false,
              "type": "Float",
              "isRequired": false,
              "attributes": []
            },
            "valuePerClick": {
              "name": "valuePerClick",
              "isArray": false,
              "type": "Float",
              "isRequired": false,
              "attributes": []
            },
            "valuePerConversion": {
              "name": "valuePerConversion",
              "isArray": false,
              "type": "Float",
              "isRequired": false,
              "attributes": []
            },
            "clientAccountId": {
              "name": "clientAccountId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "readGroups": {
              "name": "readGroups",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "writeGroups": {
              "name": "writeGroups",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "createdBy": {
              "name": "createdBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "updatedBy": {
              "name": "updatedBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "disabled": {
              "name": "disabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "Events",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "eventId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "groupClaim": "cognito:groups",
                    "provider": "userPools",
                    "allow": "groups",
                    "operations": [
                      "read"
                    ],
                    "groupsField": "readGroups",
                    "groupField": "groups"
                  },
                  {
                    "groupClaim": "cognito:groups",
                    "provider": "userPools",
                    "allow": "groups",
                    "groupsField": "writeGroups",
                    "groupField": "groups",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  },
                  {
                    "allow": "private",
                    "operations": [
                      "create"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "eventId",
            "sortKeyFieldNames": []
          }
        },
        "Campaign": {
          "name": "Campaign",
          "fields": {
            "campaignId": {
              "name": "campaignId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "registrationPageUrl": {
              "name": "registrationPageUrl",
              "isArray": false,
              "type": "AWSURL",
              "isRequired": false,
              "attributes": []
            },
            "confirmationPageUrl": {
              "name": "confirmationPageUrl",
              "isArray": false,
              "type": "AWSURL",
              "isRequired": false,
              "attributes": []
            },
            "targetInfluencerTypeEnum": {
              "name": "targetInfluencerTypeEnum",
              "isArray": false,
              "type": {
                "enum": "CampaignTargetInfluencerTypeEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "targetInfluencerType": {
              "name": "targetInfluencerType",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "campaignStatus": {
              "name": "campaignStatus",
              "isArray": false,
              "type": {
                "enum": "CampaignCampaignStatus"
              },
              "isRequired": false,
              "attributes": []
            },
            "useLandingPage": {
              "name": "useLandingPage",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "usePopup": {
              "name": "usePopup",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "useAutomatedEmail": {
              "name": "useAutomatedEmail",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "modalTitle": {
              "name": "modalTitle",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "modalText": {
              "name": "modalText",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "modalButtonText": {
              "name": "modalButtonText",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "modalUseIncentive": {
              "name": "modalUseIncentive",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "modalIncentiveText": {
              "name": "modalIncentiveText",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "modalIncentiveTCs": {
              "name": "modalIncentiveTCs",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "enableConversionTracking": {
              "name": "enableConversionTracking",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "modalPattern": {
              "name": "modalPattern",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "modalDomainPath": {
              "name": "modalDomainPath",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "modalPatternLocationEnum": {
              "name": "modalPatternLocationEnum",
              "isArray": false,
              "type": {
                "enum": "CampaignModalPatternLocationEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "postTitle": {
              "name": "postTitle",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "postBody": {
              "name": "postBody",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "postImageName": {
              "name": "postImageName",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "postImageUrl": {
              "name": "postImageUrl",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "postForwardUrl": {
              "name": "postForwardUrl",
              "isArray": false,
              "type": "AWSURL",
              "isRequired": false,
              "attributes": []
            },
            "shares": {
              "name": "shares",
              "isArray": false,
              "type": "Float",
              "isRequired": false,
              "attributes": []
            },
            "clicks": {
              "name": "clicks",
              "isArray": false,
              "type": "Float",
              "isRequired": false,
              "attributes": []
            },
            "conversions": {
              "name": "conversions",
              "isArray": false,
              "type": "Float",
              "isRequired": false,
              "attributes": []
            },
            "eventId": {
              "name": "eventId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "clientAccountId": {
              "name": "clientAccountId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "readGroups": {
              "name": "readGroups",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "writeGroups": {
              "name": "writeGroups",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "createdBy": {
              "name": "createdBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "updatedBy": {
              "name": "updatedBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "disabled": {
              "name": "disabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "Campaigns",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "campaignId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "groupClaim": "cognito:groups",
                    "provider": "userPools",
                    "allow": "groups",
                    "operations": [
                      "read"
                    ],
                    "groupsField": "readGroups",
                    "groupField": "groups"
                  },
                  {
                    "groupClaim": "cognito:groups",
                    "provider": "userPools",
                    "allow": "groups",
                    "groupsField": "writeGroups",
                    "groupField": "groups",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  },
                  {
                    "allow": "private",
                    "operations": [
                      "create"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "campaignId",
            "sortKeyFieldNames": []
          }
        },
        "CampaignShare": {
          "name": "CampaignShare",
          "fields": {
            "campaignShareId": {
              "name": "campaignShareId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "shareTypeEnum": {
              "name": "shareTypeEnum",
              "isArray": false,
              "type": {
                "enum": "CampaignShareShareTypeEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "email": {
              "name": "email",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "emailVerified": {
              "name": "emailVerified",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "familyName": {
              "name": "familyName",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "givenName": {
              "name": "givenName",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "language": {
              "name": "language",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "linkedInPostId": {
              "name": "linkedInPostId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "pictureUrl": {
              "name": "pictureUrl",
              "isArray": false,
              "type": "AWSURL",
              "isRequired": false,
              "attributes": []
            },
            "shareType": {
              "name": "shareType",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "sub": {
              "name": "sub",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "queryDate": {
              "name": "queryDate",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "campaignId": {
              "name": "campaignId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "clientAccountId": {
              "name": "clientAccountId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "readGroups": {
              "name": "readGroups",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "writeGroups": {
              "name": "writeGroups",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "createdBy": {
              "name": "createdBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "updatedBy": {
              "name": "updatedBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "disabled": {
              "name": "disabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "CampaignShares",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "campaignShareId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "campaignShareId",
            "sortKeyFieldNames": []
          }
        },
        "Conversion": {
          "name": "Conversion",
          "fields": {
            "conversionId": {
              "name": "conversionId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "campaignId": {
              "name": "campaignId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "queryDate": {
              "name": "queryDate",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "viewedCampaignShareId": {
              "name": "viewedCampaignShareId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "clientAccountId": {
              "name": "clientAccountId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "readGroups": {
              "name": "readGroups",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "writeGroups": {
              "name": "writeGroups",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "createdBy": {
              "name": "createdBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "updatedBy": {
              "name": "updatedBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "disabled": {
              "name": "disabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "Conversions",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "conversionId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "conversionId",
            "sortKeyFieldNames": []
          }
        },
        "CampaignShareClick": {
          "name": "CampaignShareClick",
          "fields": {
            "campaignShareClickId": {
              "name": "campaignShareClickId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "shareForwardUrl": {
              "name": "shareForwardUrl",
              "isArray": false,
              "type": "AWSURL",
              "isRequired": false,
              "attributes": []
            },
            "queryDate": {
              "name": "queryDate",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "campaignId": {
              "name": "campaignId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "campaignShareId": {
              "name": "campaignShareId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "clientAccountId": {
              "name": "clientAccountId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "readGroups": {
              "name": "readGroups",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "writeGroups": {
              "name": "writeGroups",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "createdBy": {
              "name": "createdBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "updatedBy": {
              "name": "updatedBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "disabled": {
              "name": "disabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "CampaignShareClicks",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "campaignShareClickId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "campaignShareClickId",
            "sortKeyFieldNames": []
          }
        },
        "CampaignShareState": {
          "name": "CampaignShareState",
          "fields": {
            "campaignShareStateId": {
              "name": "campaignShareStateId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "state": {
              "name": "state",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "campaignId": {
              "name": "campaignId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "createdBy": {
              "name": "createdBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "updatedBy": {
              "name": "updatedBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "disabled": {
              "name": "disabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "CampaignShareStates",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "campaignShareStateId"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "campaignShareStatesByState",
                "queryField": "listCampaignShareStateByState",
                "fields": [
                  "state"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "campaignShareStateId",
            "sortKeyFieldNames": []
          }
        },
        "ErrorLog": {
          "name": "ErrorLog",
          "fields": {
            "errorLogId": {
              "name": "errorLogId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "awsRequestId": {
              "name": "awsRequestId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "functionName": {
              "name": "functionName",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "errorMessage": {
              "name": "errorMessage",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "stack": {
              "name": "stack",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "clientAccountId": {
              "name": "clientAccountId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "campaignId": {
              "name": "campaignId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "readGroups": {
              "name": "readGroups",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "writeGroups": {
              "name": "writeGroups",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "createdBy": {
              "name": "createdBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "updatedBy": {
              "name": "updatedBy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "disabled": {
              "name": "disabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "ErrorLogs",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "errorLogId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "errorLogId",
            "sortKeyFieldNames": []
          }
        }
      },
      "enums": {
        "CampaignTargetInfluencerTypeEnum": {
          "name": "CampaignTargetInfluencerTypeEnum",
          "values": [
            "Attendee",
            "Speaker",
            "Exhibitor",
            "Sponsor",
            "Other"
          ]
        },
        "CampaignCampaignStatus": {
          "name": "CampaignCampaignStatus",
          "values": [
            "Draft",
            "Active",
            "Paused",
            "Completed",
            "Deleted"
          ]
        },
        "CampaignModalPatternLocationEnum": {
          "name": "CampaignModalPatternLocationEnum",
          "values": [
            "Equals",
            "Startswith",
            "Endswith",
            "Contains"
          ]
        },
        "CampaignShareShareTypeEnum": {
          "name": "CampaignShareShareTypeEnum",
          "values": [
            "Facebook",
            "Instagram",
            "LinkedIn",
            "Twitter"
          ]
        }
      },
      "nonModels": {},
      "mutations": {
        "CreateAccount": {
          "name": "CreateAccount",
          "isArray": false,
          "type": "AWSJSON",
          "isRequired": true,
          "arguments": {
            "companyName": {
              "name": "companyName",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "industry": {
              "name": "industry",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "annualEventCount": {
              "name": "annualEventCount",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "averageEventAttendance": {
              "name": "averageEventAttendance",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "currencyCode": {
              "name": "currencyCode",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "currencySymbol": {
              "name": "currencySymbol",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "givenName": {
              "name": "givenName",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "familyName": {
              "name": "familyName",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "jobTitle": {
              "name": "jobTitle",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "email": {
              "name": "email",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "mobilePhoneCountryCode": {
              "name": "mobilePhoneCountryCode",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "mobilePhone": {
              "name": "mobilePhone",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "debugLogging": {
              "name": "debugLogging",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false
            }
          }
        }
      }
    }
  },
  "storage": {
    "aws_region": "eu-north-1",
    "bucket_name": "amplify-d2xwzubstrv374-ma-buzzwaveclientassetsbuck-ipfnk21hxsze",
    "buckets": [
      {
        "name": "buzzwave-client-assets",
        "bucket_name": "amplify-d2xwzubstrv374-ma-buzzwaveclientassetsbuck-ipfnk21hxsze",
        "aws_region": "eu-north-1",
        "paths": {
          "events/*": {
            "guest": [
              "get",
              "list"
            ],
            "authenticated": [
              "get",
              "list",
              "write"
            ],
            "groupsAdmin": [
              "get",
              "list",
              "write",
              "delete"
            ],
            "groupsEveryone": [
              "get",
              "list",
              "write"
            ]
          },
          "campaigns/*": {
            "guest": [
              "get",
              "list"
            ],
            "authenticated": [
              "get",
              "list",
              "write"
            ],
            "groupsAdmin": [
              "get",
              "list",
              "write",
              "delete"
            ],
            "groupsEveryone": [
              "get",
              "list",
              "write"
            ]
          }
        }
      }
    ]
  },
  "version": "1.3",
  "custom": {
    "API": {
      "buzzwave-http-api": {
        "endpoint": "https://2nc5rwhnfk.execute-api.eu-north-1.amazonaws.com/",
        "region": "eu-north-1",
        "apiName": "buzzwave-http-api"
      }
    }
  }
}